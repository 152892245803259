export default function (other) {
  other = other || {}
  return {
    id: other.id || null,
    module: other.module || null,
    registrationTime: other.registrationTime || null,
    action: other.action || null,
    executor: other.executor || null,
/*
    fields: other.fields || null,
*/
    description: other.description || null,

  }
}
